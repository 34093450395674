import { useEffect, useState } from "react";
import styled from "styled-components";
import { device } from "../styles/Breakpoints";
import { useTranslation } from "react-i18next";

const HeaderContainer = styled.header`
  position: absolute;
  height: 70px;

  @media ${device.md} {
    height: 100px;
  }
`;

// LOGO
const Logo = styled.a`
  display: block;
  position: relative;
  width: 50px;
  height: 50px;

  @media ${device.md} {
    width: 70px;
    height: 70px;
  }
`;

const LogoImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

// MOBILE
const MobileMenuWrapper = styled.div`
  @media ${device.md} {
    display: none;
  }
`;

const MobileMenu = styled.div<{
  $isMenuOpen: boolean;
  $isScrollActive: boolean;
}>`
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 70px;
  padding: 0 15px;
  background-color: rgba(0, 0, 0, 0.2);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
  z-index: 99;

  @media ${device.sm} {
    padding: 0 60px;
  }

  ${({ $isMenuOpen, theme }) =>
    $isMenuOpen && `background-color: ${theme.colors.headerBg}`}

  ${({ $isScrollActive, theme }) =>
    $isScrollActive && `background-color: ${theme.colors.headerBg}`}
`;

const MobileNavContainer = styled.div<{ $active: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 98;
  padding-top: 60px;
  background-color: ${({ theme }) => theme.colors.darkPurple};
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;

  ${({ $active }) =>
    $active ? `transform: translateX(0%);` : `transform: translateX(-100%);`}
`;

const MobileNav = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
`;

const MobileNavItem = styled.a`
  position: relative;
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.OpenSans};
  font-size: 24px;
  font-weight: 500;
  cursor: pointer;

  &::after {
    content: "";
    display: block;
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: ${({ theme }) => theme.colors.pink};
    transform: scaleX(0);
    transform-origin: left;
    transition: 0.4s ease;
  }

  &:hover::after {
    transform: scaleX(1);
  }

  @media ${device.sm} {
    font-size: 32px;
  }
`;

const HamburgerButton = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 16px;
  z-index: 99;
  cursor: pointer;
`;

const HamburgerLine = styled.span<{ type: string; $active: boolean }>`
  position: absolute;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.white};
  border-radius: 1px;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;

  ${({ type, $active }) => {
    if ($active) {
      if (type === "top") {
        return `transform: translateY(0px) rotate(45deg);`;
      }
      if (type === "middle") {
        return `opacity: 0;`;
      }
      if (type === "bottom") {
        return `transform: translateY(0px) rotate(-45deg);`;
      }
    } else {
      if (type === "top") {
        return `transform: translateY(8px);`;
      }
      if (type === "middle") {
        return `opacity: 1;`;
      }
      if (type === "bottom") {
        return `transform: translateY(-8px);`;
      }
    }
  }};
`;

// DESKTOP
const DesktopMenuWrapper = styled.div<{ $isScrollActive: boolean }>`
  display: none;

  @media ${device.md} {
    display: flex;
    position: fixed;
    top: 0;
    width: 100%;
    height: 100px;
    background-color: rgba(0, 0, 0, 0.2);
    transition: background-color 0.4s ease;
    z-index: 98;

    ${({ $isScrollActive, theme }) =>
      $isScrollActive && `background-color: ${theme.colors.headerBg}`}
  }
`;

const DesktopMenu = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 60px;

  @media ${device.xl} {
    max-width: 1440px;
    margin: 0 auto;
  }
`;

const DesktopNav = styled.nav`
  position: relative;
  padding: 45px 0 33px;
  display: flex;
  flex-direction: row;
  gap: 40px;
`;

const DesktopNavItem = styled.a`
  position: relative;
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.OpenSans};
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;

  &::after {
    content: "";
    display: block;
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: ${({ theme }) => theme.colors.pink};
    transform: scaleX(0);
    transform-origin: left;
    transition: 0.4s ease;
  }

  &:hover::after {
    transform: scaleX(1);
  }
`;

const Header = () => {
  const { t } = useTranslation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isHeaderScrolled, setIsHeaderScrolled] = useState(false);

  const scrollToSection = (e: any, name: string) => {
    let header = document.querySelector("header");
    let section = document.getElementById(name);
    e.preventDefault();
    setIsMenuOpen(false);

    let headerHeight = header?.offsetHeight || 0;
    let position = section && section.getBoundingClientRect();

    position &&
      window.scrollTo({
        top: position.top + window.scrollY - headerHeight,
        behavior: "smooth",
      });
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () =>
        setIsHeaderScrolled(window.scrollY > 0)
      );
    }
  }, []);

  useEffect(() => {
    if (isMenuOpen) {
      if (typeof window != "undefined" && window.document) {
        document.body.style.overflow = "hidden";
      }
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isMenuOpen]);

  return (
    <HeaderContainer>
      <MobileMenuWrapper>
        <MobileMenu $isMenuOpen={isMenuOpen} $isScrollActive={isHeaderScrolled}>
          <Logo href="/" onClick={(e) => scrollToSection(e, "hero")}>
            <LogoImage src="./assets/logo.png" alt="logo" />
          </Logo>
          <HamburgerButton onClick={() => setIsMenuOpen(!isMenuOpen)}>
            <HamburgerLine $active={isMenuOpen} type="top" />
            <HamburgerLine $active={isMenuOpen} type="middle" />
            <HamburgerLine $active={isMenuOpen} type="bottom" />
          </HamburgerButton>
        </MobileMenu>
        <MobileNavContainer $active={isMenuOpen}>
          <MobileNav>
            <MobileNavItem href="/" onClick={(e) => scrollToSection(e, "hero")}>
              {t("header:nav:main")}
            </MobileNavItem>
            <MobileNavItem
              href="/"
              onClick={(e) => scrollToSection(e, "aboutus")}
            >
              {t("header:nav:aboutus")}
            </MobileNavItem>
            <MobileNavItem
              href="/"
              onClick={(e) => scrollToSection(e, "offer")}
            >
              {t("header:nav:offer")}
            </MobileNavItem>
            <MobileNavItem
              href="/"
              onClick={(e) => scrollToSection(e, "contact")}
            >
              {t("header:nav:contact")}
            </MobileNavItem>
            <MobileNavItem href="/" onClick={(e) => scrollToSection(e, "faqs")}>
              {t("header:nav:faqs")}
            </MobileNavItem>
          </MobileNav>
        </MobileNavContainer>
      </MobileMenuWrapper>
      <DesktopMenuWrapper $isScrollActive={isHeaderScrolled}>
        <DesktopMenu>
          <Logo href="/" onClick={(e) => scrollToSection(e, "hero")}>
            <LogoImage src="./assets/logo.png" alt="logo" />
          </Logo>
          <DesktopNav>
            <DesktopNavItem
              href="/"
              onClick={(e) => scrollToSection(e, "hero")}
            >
              {t("header:nav:main")}
            </DesktopNavItem>
            <DesktopNavItem
              href="/"
              onClick={(e) => scrollToSection(e, "aboutus")}
            >
              {t("header:nav:aboutus")}
            </DesktopNavItem>
            <DesktopNavItem
              href="/"
              onClick={(e) => scrollToSection(e, "offer")}
            >
              {t("header:nav:offer")}
            </DesktopNavItem>
            <DesktopNavItem
              href="/"
              onClick={(e) => scrollToSection(e, "contact")}
            >
              {t("header:nav:contact")}
            </DesktopNavItem>
            <DesktopNavItem
              href="/"
              onClick={(e) => scrollToSection(e, "faqs")}
            >
              {t("header:nav:faqs")}
            </DesktopNavItem>
          </DesktopNav>
        </DesktopMenu>
      </DesktopMenuWrapper>
    </HeaderContainer>
  );
};

export default Header;
