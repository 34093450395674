import { device } from "../styles/Breakpoints";
import { styled } from "styled-components";
import { SectionTitle } from "../components/Text";
import { useTranslation } from "react-i18next";
import Faq from "../components/Faq";

const Container = styled.section`
  position: relative;
  padding-bottom: 60px;
  background-color: ${({ theme }) => theme.colors.white};

  @media ${device.md} {
    padding-bottom: 100px;
  }
`;

const TitleContainer = styled.div`
  position: relative;
  padding: 60px 0 30px;

  @media ${device.md} {
    display: none;
  }
`;

const Title = styled(SectionTitle)`
  color: ${({ theme }) => theme.colors.darkPurple};
  text-align: center;
`;

const FaqsContent = styled.div`
  display: flex;
  flex-direction: column;

  @media ${device.md} {
    flex-direction: row;
    justify-content: center;
    gap: 60px;
    padding: 60px 60px 0;
  }

  @media ${device.lg} {
    gap: 120px;
    max-width: 1280px;
    margin: 0 auto;
  }

  @media ${device.xl} {
    max-width: 1440px;
  }
`;

const FaqsInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0 15px;
  max-width: 360px;
  margin: 0 auto 30px;

  @media ${device.md} {
    padding: 0;
    max-width: unset;
    margin: 0;
    width: 400px;
    min-width: 400px;
  }

  @media ${device.lg} {
    width: 460px;
    min-width: 460px;
  }
`;

const FaqsTitle = styled(Title)`
  display: none;

  @media ${device.md} {
    display: block;
    text-align: left;
  }
`;

const SubText = styled.p`
  color: ${({ theme }) => theme.colors.darkPurple};
  font-family: ${({ theme }) => theme.fonts.OpenSans};
  font-size: 16px;
  font-weight: 500;

  @media ${device.lg} {
    font-size: 18px;
  }
`;

const EncouragementText = styled.p`
  color: ${({ theme }) => theme.colors.darkPurple};
  font-family: ${({ theme }) => theme.fonts.OpenSans};
  font-size: 16px;
  font-weight: 500;
  font-style: italic;

  @media ${device.lg} {
    font-size: 18px;
  }
`;

const FaqsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0 15px;
  max-width: 360px;
  margin: 0 auto;

  @media ${device.md} {
    padding: 0;
    max-width: 500px;
    margin: 0;
  }

  @media ${device.xl} {
    max-width: 700px;
  }
`;

const Faqs = () => {
  const { t } = useTranslation();

  return (
    <Container id="faqs">
      <TitleContainer>
        <Title>{t("faqs:title")}</Title>
      </TitleContainer>
      <FaqsContent>
        <FaqsInfoContainer>
          <FaqsTitle>{t("faqs:title")}</FaqsTitle>
          <SubText
            dangerouslySetInnerHTML={{
              __html: t("faqs:subText", {
                interpolation: { escapeValue: false },
              }),
            }}
          ></SubText>

          <EncouragementText>{t("faqs:encouragementText")}</EncouragementText>
        </FaqsInfoContainer>
        <FaqsList>
          <Faq
            question={t("faqs:list:first:question")}
            answer={t("faqs:list:first:answer")}
          />
          <Faq
            question={t("faqs:list:second:question")}
            answer={t("faqs:list:second:answer")}
          />
          <Faq
            question={t("faqs:list:third:question")}
            answer={t("faqs:list:third:answer")}
          />
          <Faq
            question={t("faqs:list:fourth:question")}
            answer={t("faqs:list:fourth:answer")}
          />
          <Faq
            question={t("faqs:list:fifth:question")}
            answer={t("faqs:list:fifth:answer")}
          />
        </FaqsList>
      </FaqsContent>
    </Container>
  );
};

export default Faqs;
