import { SVGProps } from "react";

const UserSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="41"
    height="41"
    viewBox="0 0 41 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.59033 35.8262C8.59033 29.2218 13.9443 23.8678 20.5487 23.8678C27.1531 23.8678 32.507 29.2218 32.507 35.8262M27.382 11.9095C27.382 15.6835 24.3225 18.7428 20.5487 18.7428C16.7747 18.7428 13.7153 15.6835 13.7153 11.9095C13.7153 8.13556 16.7747 5.07617 20.5487 5.07617C24.3225 5.07617 27.382 8.13556 27.382 11.9095Z"
      stroke="#2A1B3D"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default UserSvg;
