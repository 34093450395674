import { styled } from "styled-components";
import { SectionTitle } from "../components/Text";
import { useTranslation } from "react-i18next";
import { device } from "../styles/Breakpoints";

const Container = styled.section`
  position: relative;
  padding-bottom: 60px;
  background-color: ${({ theme }) => theme.colors.white};

  @media ${device.md} {
    padding-bottom: 100px;
  }
`;

const TitleContainer = styled.div`
  position: relative;
  padding: 60px 0 30px;

  @media ${device.md} {
    padding: 60px 0;
  }

  @media ${device.md} {
    padding: 60px 0 80px;
  }
`;

const Title = styled(SectionTitle)`
  color: ${({ theme }) => theme.colors.darkPurple};
  text-align: center;
`;

const AboutUsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  padding: 0 15px;
  max-width: 360px;
  margin: 0 auto;

  @media ${device.md} {
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    gap: 80px;
    padding: 0 60px;
    max-width: unset;
    margin: 0;
  }

  @media ${device.lg} {
    gap: 120px;
    max-width: 1280px;
    margin: 0 auto;
  }

  @media ${device.xl} {
    max-width: 1440px;
  }
`;

const AboutUsContent = styled.div`
  position: relative;

  @media ${device.md} {
    max-width: 360px;
    order: 2;
  }

  @media ${device.md} {
    max-width: 600px;
  }
`;

const WhatWeOfferText = styled.p`
  color: ${({ theme }) => theme.colors.darkPurple};
  font-family: ${({ theme }) => theme.fonts.OpenSans};
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 20px;

  @media ${device.lg} {
    font-size: 18px;
  }
`;

const SizeInfoText = styled.p`
  color: ${({ theme }) => theme.colors.darkPurple};
  font-family: ${({ theme }) => theme.fonts.OpenSans};
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 20px;

  @media ${device.lg} {
    font-size: 18px;
  }
`;

const ServicesContainer = styled.div``;

const ServicesDesc = styled.p`
  color: ${({ theme }) => theme.colors.darkPurple};
  font-family: ${({ theme }) => theme.fonts.OpenSans};
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 15px;

  @media ${device.lg} {
    font-size: 18px;
  }
`;

const ServicesList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 5px;
  list-style: none;
`;

const ServicesItem = styled.li`
  display: flex;
  flex-direction: row;
  gap: 10px;
  color: ${({ theme }) => theme.colors.darkPurple};
  font-family: ${({ theme }) => theme.fonts.OpenSans};
  font-size: 16px;
  font-weight: bold;

  @media ${device.lg} {
    font-size: 18px;
  }
`;

const Point = styled.div`
  align-self: flex-end;
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  margin-bottom: 5px;
  background-color: ${({ theme }) => theme.colors.darkPurple};
`;

const AboutUsImageContainer = styled.div<{ $src?: string }>`
  position: relative;
  width: 100%;
  height: 300px;
  border-radius: 30px;
  overflow: hidden;
  max-width: 360px;
  background: ${({ $src }) => `url(${$src})`};
  background-size: cover;
  background-position: center bottom;
  background-repeat: no-repeat;

  @media ${device.md} {
    min-width: 360px;
    order: 1;
  }

  @media ${device.xl} {
    height: 400px;
    max-width: 560px;
    border-radius: 60px;
  }
`;

const AboutUs = () => {
  const { t } = useTranslation();

  return (
    <Container id="aboutus">
      <TitleContainer>
        <Title>{t("aboutUs:title")}</Title>
      </TitleContainer>
      <AboutUsContainer>
        <AboutUsContent>
          <WhatWeOfferText>{t("aboutUs:whatWeOffer")}</WhatWeOfferText>
          <SizeInfoText
            dangerouslySetInnerHTML={{
              __html: t("aboutUs:tentSizeInfo", {
                interpolation: { escapeValue: false },
              }),
            }}
          ></SizeInfoText>
          <ServicesContainer>
            <ServicesDesc>{t("aboutUs:ourServices:title")}</ServicesDesc>
            <ServicesList>
              <ServicesItem>
                <Point />
                {t("aboutUs:ourServices:birthdays")}
              </ServicesItem>
              <ServicesItem>
                <Point />
                {t("aboutUs:ourServices:bachelorParties")}
              </ServicesItem>
              <ServicesItem>
                <Point />
                {t("aboutUs:ourServices:familyOrCorporateEvents")}
              </ServicesItem>
            </ServicesList>
          </ServicesContainer>
        </AboutUsContent>
        <AboutUsImageContainer $src="./assets/about_image.webp" />
      </AboutUsContainer>
    </Container>
  );
};

export default AboutUs;
