import { styled } from "styled-components";
import { useTranslation } from "react-i18next";
import { MainTitle } from "../components/Text";
import Button from "../components/Button";
import { device } from "../styles/Breakpoints";
import Videos from "../components/Videos";
import LasersSvg from "../components/icons/LasersSvg";

const HeroBackgroundContainer = styled.div`
  position: relative;
  padding-top: 70px;
  background-image: url("./assets/hero_bg.svg");
  background-repeat: no-repeat;
  background-size: cover;

  @media ${device.md} {
    padding-top: 100px;
  }
`;

const Container = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 70px 0 60px;
  overflow: hidden;

  @media ${device.md} {
    padding: 100px 0;
  }

  @media ${device.lg} {
    padding: 140px 0 100px;
  }

  @media ${device.xl} {
    padding: 160px 0 100px;
  }
`;

const WelcomeContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 0 15px;
  max-width: 360px;
  margin: 0 auto 60px;

  @media ${device.md} {
    flex-direction: row;
    justify-content: center;
    align-items: unset;
    gap: 80px;
    padding: 0 60px;
    max-width: unset;
    margin: 0 0 60px;
  }

  @media ${device.lg} {
    justify-content: space-between;
    gap: 120px;
    padding: 0 60px;
    margin: 0 auto 120px;
    max-width: 1280px;
  }

  @media ${device.xl} {
    max-width: 1440px;
  }
`;

const WelcomeContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media ${device.md} {
    min-width: 360px;
  }

  @media ${device.lg} {
    width: unset;
  }
`;

const TitleContainer = styled.div`
  margin-bottom: 10px;
`;

const Title = styled(MainTitle)`
  display: inline;
  position: relative;
  z-index: 1;
`;

const Desc = styled.p`
  font-family: ${({ theme }) => theme.fonts.OpenSans};
  color: ${({ theme }) => theme.colors.white};
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 30px;

  @media ${device.lg} {
    font-size: 18px;
    margin-bottom: 40px;
  }

  @media ${device.xl} {
    font-size: 24px;
  }
`;

const WelcomeImage = styled.div<{ $src?: string }>`
  position: relative;
  width: 100%;
  height: 300px;
  border-radius: 30px;
  overflow: hidden;
  max-width: 360px;
  background: ${({ $src }) => `url(${$src})`};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @media ${device.lg} {
    height: 400px;
    max-width: 560px;
    border-radius: 60px;
  }

  @media ${device.xl} {
    height: 450px;
    max-width: 660px;
  }
`;

const QuoteContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding: 20px;
  background: linear-gradient(
    180deg,
    rgb(43 22 71 / 34%) 0%,
    rgb(237 229 229 / 0%) 99.99%,
    rgb(247 242 242 / 0%) 100%
  );
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  border-radius: 30px;
  max-width: 330px;
  margin: 0 auto 30px;

  @media ${device.md} {
    width: 600px;
    padding: 40px;
    max-width: unset;
    margin: 0 auto 60px;
  }

  @media ${device.lg} {
    width: 800px;
    padding: 40px 110px;
    margin: 0 auto 120px;
  }
`;

const QuoteTitle = styled.h2`
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.OpenSans};
  font-size: 18px;
  font-weight: 500;
  text-align: center;

  @media ${device.lg} {
    font-size: 24px;
  }
`;

const QuoteDesc = styled.p`
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.OpenSans};
  font-size: 16px;
  font-style: italic;
  text-align: center;

  @media ${device.lg} {
    font-size: 18px;
  }
`;

const CarouselContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;

  & > a {
    align-self: center;
  }

  @media ${device.lg} {
    gap: 60px;
  }
`;

const LasersContainer = styled.div`
  display: none;
  position: absolute;
  top: 0px;
  right: -20px;
  width: 300px;
  height: 300px;
  transform: rotate(180deg);

  @media ${device.md} {
    display: block;
  }

  @media ${device.lg} {
    width: 400px;
    height: 400px;
  }

  @media ${device.xl} {
    width: 450px;
    height: 450px;
  }

  @media ${device.xxl} {
    width: 500px;
    height: 500px;
  }

  & > svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  & > svg .line1 {
    transform-origin: 0px 500px;
    transform: rotate(290deg);
    animation-name: line1-anim;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-direction: alternate;

    @keyframes line1-anim {
      0% {
        transform: rotate(290deg);
      }

      100% {
        transform: rotate(350deg);
      }
    }
  }

  & > svg .line2 {
    transform-origin: 0px 500px;
    transform: rotate(300deg);
    animation-name: line1-anim;
    animation-duration: 1s;
    animation-delay: 0.2s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-direction: alternate;

    @keyframes line1-anim {
      0% {
        transform: rotate(300deg);
      }

      100% {
        transform: rotate(340deg);
      }
    }
  }

  & > svg .line3 {
    transform-origin: 0px 500px;
    transform: rotate(310deg);
    animation-name: line1-anim;
    animation-duration: 1s;
    animation-delay: 0.4s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-direction: alternate;

    @keyframes line1-anim {
      0% {
        transform: rotate(310deg);
      }

      50% {
        transform: rotate(340deg);
      }

      100% {
        transform: rotate(290deg);
      }
    }
  }

  & > svg .line4 {
    transform-origin: 0px 500px;
    transform: rotate(320deg);
    animation-name: line1-anim;
    animation-duration: 1s;
    animation-delay: 0.6s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-direction: alternate;

    @keyframes line1-anim {
      0% {
        transform: rotate(320deg);
      }

      50% {
        transform: rotate(350deg);
      }

      100% {
        transform: rotate(320deg);
      }
    }
  }

  & > svg .line5 {
    transform-origin: 0px 500px;
    transform: rotate(290deg);
    animation-name: line1-anim;
    animation-duration: 1s;
    animation-delay: 0.8s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-direction: alternate;

    @keyframes line1-anim {
      0% {
        transform: rotate(290deg);
      }

      100% {
        transform: rotate(340deg);
      }
    }
  }

  & > svg .line6 {
    transform-origin: 0px 500px;
    transform: rotate(290deg);
    animation-name: line1-anim;
    animation-duration: 1s;
    animation-delay: 1s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-direction: alternate;

    @keyframes line1-anim {
      0% {
        transform: rotate(290deg);
      }

      100% {
        transform: rotate(340deg);
      }
    }
  }

  & > svg .line7 {
    transform-origin: 0px 500px;
    transform: rotate(290deg);
    animation-name: line1-anim;
    animation-duration: 1s;
    animation-delay: 1.2s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-direction: alternate;

    @keyframes line1-anim {
      0% {
        transform: rotate(290deg);
      }

      100% {
        transform: rotate(350deg);
      }
    }
  }
`;

const CheckOfferButton = styled(Button)``;

const Hero = () => {
  const { t } = useTranslation();

  const scrollToSection = (e: any, name: string) => {
    let header = document.querySelector("header");
    let section = document.getElementById(name);
    e.preventDefault();

    let headerHeight = header?.offsetHeight || 0;
    let position = section && section.getBoundingClientRect();

    position &&
      window.scrollTo({
        top: position.top + window.scrollY - headerHeight,
        behavior: "smooth",
      });
  };

  return (
    <HeroBackgroundContainer id="hero">
      <Container>
        <WelcomeContainer>
          <WelcomeContent>
            <TitleContainer>
              <Title>{t("hero:title")}</Title>
            </TitleContainer>
            <Desc>{t("hero:desc")}</Desc>
            <Button
              title={t("hero:bookNowBtn")}
              isLink
              href="/"
              onClick={(e) => scrollToSection(e, "contact")}
            />
          </WelcomeContent>
          <WelcomeImage $src="./assets/hero_image.webp" />
        </WelcomeContainer>
        <QuoteContainer>
          <QuoteTitle>{t("hero:quote:title")}</QuoteTitle>
          <QuoteDesc>{t("hero:quote:desc")}</QuoteDesc>
        </QuoteContainer>
        <CarouselContainer>
          <Videos />
          <CheckOfferButton
            title={t("hero:checkOurOfferBtn")}
            isLink
            href="/"
            onClick={(e) => scrollToSection(e, "offer")}
          />
        </CarouselContainer>
        <LasersContainer>
          <LasersSvg />
        </LasersContainer>
      </Container>
    </HeroBackgroundContainer>
  );
};

export default Hero;
