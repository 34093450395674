import { FC } from "react";
import { styled, css } from "styled-components";
import ArrowRight from "./icons/ArrowRightSvg";
import { device } from "../styles/Breakpoints";

const baseButtonStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  position: relative;
  padding: 15px 30px;
  background-color: ${({ theme }) => theme.colors.btnBg};
  border-radius: 30px;
  transition: background-color 0.4s ease;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.btnBgHover};
  }

  &:hover > svg {
    transform: translateX(5px);
  }
`;

const TextButton = styled.span`
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.OpenSans};
  font-size: 18px;
  font-weight: 500;

  @media ${device.xl} {
    font-size: 24px;
  }
`;

const ArrowRightIcon = styled(ArrowRight)`
  position: relative;
  width: 24px;
  height: 24px;
  transition: transform 0.4s ease;

  @media ${device.xl} {
    width: 30px;
    height: 30px;
  }
`;

const DefaultButton = styled.button`
  ${baseButtonStyles}
`;

const DefaultLink = styled.a`
  ${baseButtonStyles}
`;

const SubmitButton = styled.button`
  ${baseButtonStyles}
  margin-top: 20px;

  @media ${device.md} {
    align-self: center;
    min-width: 200px;
  }
`;

const Loader = styled.div`
  height: 32px;
  width: 32px;
  animation: loader-1-1 4.8s linear infinite;

  @keyframes loader-1-1 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const LoaderElem = styled.span`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  height: 32px;
  width: 32px;
  clip: rect(0, 32px, 32px, 16px);
  animation: loader-1-2 1.2s linear infinite;

  @keyframes loader-1-2 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(220deg);
    }
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    height: 32px;
    width: 32px;
    clip: rect(0, 32px, 32px, 16px);
    border: 3px solid #fff;
    border-radius: 50%;
    animation: loader-1-3 1.2s cubic-bezier(0.77, 0, 0.175, 1) infinite;

    @keyframes loader-1-3 {
      0% {
        transform: rotate(-140deg);
      }
      50% {
        transform: rotate(-160deg);
      }
      100% {
        transform: rotate(140deg);
      }
    }
  }
`;

interface Props {
  title: string;
  onClick?: (e: any) => void;
  disabled?: boolean;
  isSubmitBtn?: boolean;
  isLink?: boolean;
  href?: string;
  isLoading?: boolean;
}

const Button: FC<Props> = ({
  title,
  onClick,
  disabled,
  isSubmitBtn,
  isLink,
  href,
  isLoading,
}) => {
  if (isLink) {
    return (
      <DefaultLink href={href} onClick={onClick}>
        <TextButton>{title}</TextButton>
        <ArrowRightIcon />
      </DefaultLink>
    );
  }

  if (isSubmitBtn) {
    return (
      <SubmitButton type="submit" disabled={disabled}>
        {isLoading ? (
          <Loader>
            <LoaderElem />
          </Loader>
        ) : (
          <TextButton>{title}</TextButton>
        )}
      </SubmitButton>
    );
  }

  return (
    <DefaultButton onClick={onClick} disabled={disabled}>
      <TextButton>{title}</TextButton>
    </DefaultButton>
  );
};

export default Button;
