const translations = {
  header: {
    nav: {
      main: "Główna",
      aboutus: "O nas",
      offer: "Oferta",
      contact: "Rezerwacja",
      faqs: "FAQs",
    },
  },
  hero: {
    title: "DISCO NAMIOT",
    desc: "Wynajem dmuchanego namiotu imprezowego.",
    bookNowBtn: "Zarezerwuj już teraz",
    quote: {
      title:
        "Czy marzysz o uczuciu imprezy klubowej, ale nie chcesz wychodzić z domu?",
      desc: "Mamy dla Ciebie idealne rozwiązanie! Wynajmij nasz imprezowy namiot, który przeniesie Cię wprost na parkiet.",
    },
    checkOurOfferBtn: "Sprawdź naszą ofertę",
  },
  aboutUs: {
    title: "O NAS",
    whatWeOffer:
      "Oferujemy kompleksowe zorganizowanie imprezy, co oznacza, że my zajmiemy się rozłożeniem namiotu i przygotowaniem wszystkiego na miejscu. Wystarczy, że dostarczysz nam jedynie dostęp do prądu.",
    tentSizeInfo:
      "Disco namiot o wymiarze <b>5m x 7m </b>zapewni Ci wystarczającą przestrzeń dla gości.",
    ourServices: {
      title: "Nasze usługi są idealne na różnego rodzaju imprezy, takie jak:",
      birthdays: "urodziny",
      bachelorParties: "wieczory panieńskie lub kawalerskie",
      familyOrCorporateEvents: "imprezy rodzinne lub firmowe",
    },
  },
  offer: {
    title: "OFERTA",
    individual: {
      title: "Osoby prywatne",
      pncz: {
        title: "Poniedziałek - Czwartek",
        value: "999zł/impreza",
      },
      ptnd: {
        title: "Piątek-Niedziela i święta",
        value: "1299zł/impreza",
      },
    },
    business: {
      title: "Firmy",
      rental: {
        title: "Wynajem na godziny:",
        option1: "do 4h - 300zł/h",
        option2: "do 6h - 250zł/h",
        option3: "do 8h - 200zł/h",
        option4: "powyżej 8h - wycena indywidualna",
      },
    },
    delivery: {
      title: "Dowóz",
      local: {
        title: "Na terenie Ochotnicy Górnej oraz w promieniu 50 km",
        value: "za darmo",
      },
      other: {
        title: "Pozostałe odległości:",
        value: "cena do uzgodnienia",
      },
    },
    additionalInfo: {
      prices: "Podane ceny są kwotami brutto.",
      delivery: "Realizujemy usługi na terenie całej Polski.",
    },
  },
  equipment: {
    title: "WYPOSAŻENIE",
    speakers:
      "<b>Głośniki z mikserem</b>, dostosuj muzykę do swoich preferencji i zorganizuj własną imprezę z ulubionymi utworami. W zestawie znajduję się mikrofon idealny do karaoke.",
    laserSystem:
      "<b>System laserowy</b>, który dostarcza niesamowitych efektów wizualnych podczas imprez. Lasery potrafią generować różnorodne wzory i efekty świetlne, wprowadzając niepowtarzalną atmosferę na każde wydarzenie.",
    smokeMaker:
      "<b>Wytwornica dymu</b> wprowadzi Cię w idealny nastrój imprezowy. Poczujesz się jak w prawdziwym klubie, tańcząc wśród migoczących świateł i tajemniczego dymu.",
  },
  contact: {
    title: "REZERWACJA",
    form: {
      name: "Imię",
      surname: "Nazwisko",
      email: "E-mail",
      dateEvent: "Data imprezy",
      phone: "Telefon",
      locality: "Miejscowość",
      address1: "Ulica",
      address2: "Nr domu/mieszkania",
      zipCode: "Kod pocztowy",
      rentalType: "Wynajem",
      rental: {
        individual: "Osoba prywatna",
        company: "Firma",
      },
      send: "Prześlij",
      successful: "Rezerwacja została pomyślnie wysłana.",
    },
    errors: {
      invalidEmail: "Niepoprawny e-mail.",
      requiredField: "To pole jest wymagane.",
      invalidPhone: "Niepoprawny numer telefonu.",
    },
  },
  faqs: {
    title: "MASZ PYTANIE?",
    subText:
      "Jeśli napotkasz trudność w odnalezieniu odpowiedzi na pytanie, które Cię ciekawi, prosimy o kontakt poprzez nasz adres e-mail: <a href='mailto:disconamiot@gmail.com'><b>disconamiot@gmail.com</b></a>.",
    encouragementText: "Chętnie Ci pomożemy!",
    list: {
      first: {
        question: "W jaki sposób mogę wynająć namiot?",
        answer:
          "Zadzwoń do nas, wypełnij formularz kontaktowy na stronie, napisz na Facebooku, Instagramie lub wyślij wiadomość e-mail aby zarezerwować termin.",
      },
      second: {
        question: "Czy muszę mieć 18 lat, aby wynająć namiot?",
        answer:
          "Nie, wystarczy zgoda rodzica lub opiekuna prawnego, z którym podpiszemy umowę.",
      },
      third: {
        question: "Jakie zasilanie ma namiot?",
        answer:
          "Namiot jest zasilany napięciem 230V. Od Ciebie potrzebujemy jedynie dostępu do prądu.",
      },
      fourth: {
        question: "Czy wymagana jest kaucja?",
        answer: "Tak, jest wymagana. Zwracamy ją po zakończonym zleceniu.",
      },
      fifth: {
        question: "Ile osób pomieści namiot?",
        answer: "Namiot pomieści maksymalnie 45 osób.",
      },
    },
  },
  footer: {
    copyrights: "©copyrights disconamiot.pl",
    phoneNumber: "+48 578450212",
    mail: "disconamiot@gmail.com",
  },
};

export default translations;
