import { ChangeEvent, FC, useRef, useState } from "react";
import { styled } from "styled-components";
import { device } from "../styles/Breakpoints";

const FormItem = styled.div`
  position: relative;
  width: 100%;
`;

const FormLabel = styled.label<{ $isFocused: boolean }>`
  position: absolute;
  left: 0;
  top: 10px;
  background-color: ${({ theme }) => theme.colors.lightGray};
  z-index: 10;
  font-family: ${({ theme }) => theme.fonts.OpenSans};
  font-size: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.darkPurple};
  transition: transform 150ms ease-out, font-size 150ms ease-out;
  pointer-events: none;

  ${({ $isFocused }) =>
    $isFocused &&
    `
    transform: translateY(-125%);
    font-size: .75em!important;
  `}

  @media ${device.lg} {
    font-size: 18px;
  }
`;

const FormInput = styled.input`
  position: relative;
  padding: 12px 0px 5px;
  width: 100%;
  font-family: ${({ theme }) => theme.fonts.OpenSans};
  font-size: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.darkPurple};
  box-shadow: 0 1px 0 0 ${({ theme }) => theme.colors.darkPurple};
  background-color: ${({ theme }) => theme.colors.lightGray};
  transition: box-shadow 150ms ease-out;

  &:focus {
    box-shadow: 0 2px 0 0 ${({ theme }) => theme.colors.darkPurple};
  }

  @media ${device.lg} {
    font-size: 18px;
  }
`;

const FormError = styled.p`
  color: ${({ theme }) => theme.colors.red};
  font-family: ${({ theme }) => theme.fonts.OpenSans};
  font-size: 14px;
  font-weight: 500;
  padding-top: 10px;
`;

interface Props {
  id: string;
  label?: string;
  value?: string;
  error?: string;
  required?: boolean;
  onChange?: (id: string, value: string | number) => void;
  setFieldError?: (id: string, value: any) => void;
}

const Input: FC<Props> = ({
  id,
  label,
  value,
  error,
  onChange,
  setFieldError,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    if (inputRef && inputRef.current) inputRef.current.focus();
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange?.(event.target.id, event.target.value);
    setFieldError?.(event.target.id, undefined);
  };

  return (
    <>
      <FormItem onClick={handleClick}>
        <FormLabel $isFocused={isFocused}>{label}</FormLabel>
        <FormInput
          ref={inputRef}
          id={id}
          value={value}
          onChange={(e) => handleChange(e)}
          onFocus={() => setIsFocused(true)}
          onBlur={() => {
            const inputValue = inputRef.current?.value;
            if (inputValue === "") {
              setIsFocused(false);
            }
          }}
          autoComplete="off"
        />
        {!!error && <FormError>{error}</FormError>}
      </FormItem>
    </>
  );
};

export default Input;
