import { SVGProps } from "react";

const FacebookSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="90"
    height="90"
    viewBox="0 0 90 90"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M45 82.5C65.7107 82.5 82.5 65.7107 82.5 45C82.5 24.2893 65.7107 7.5 45 7.5C24.2893 7.5 7.5 24.2893 7.5 45C7.5 65.7107 24.2893 82.5 45 82.5Z"
      fill="#2A1B3D"
    />
    <path
      className="background"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M35.6892 81.3351V55.5488H29V45.8788H35.6892V38.5577C35.6892 38.0551 35.684 37.5891 35.679 37.1518C35.6484 34.4422 35.6303 32.8343 36.9538 30.4206C38.4917 27.6175 42.8295 23.9124 50.3322 24.0016C57.8367 24.094 61 24.816 61 24.816L59.3745 35.1219C59.3745 35.1219 54.5824 33.8565 52.2303 34.3075C49.8813 34.7586 48.5267 36.2056 48.5267 39.8215V45.8788H59.3745L58.1983 55.5488H48.5267V82.3363C67.5836 80.5588 82.5 64.5214 82.5 45C82.5 24.2893 65.7107 7.5 45 7.5C24.2893 7.5 7.5 24.2893 7.5 45C7.5 62.4966 19.4826 77.1945 35.6892 81.3351Z"
      fill="white"
    />
  </svg>
);
export default FacebookSvg;
