import { css, styled } from "styled-components";
import { SectionTitle } from "../components/Text";
import { useTranslation } from "react-i18next";
import SmokemakerSvg from "../components/icons/SmokemakerSvg";
import DiscoballSvg from "../components/icons/DiscoballSvg";
import SpeakersSvg from "../components/icons/SpeakersSvg";
import { device } from "../styles/Breakpoints";
import StarSvg from "../components/icons/StarSvg";

const Container = styled.section`
  position: relative;
  padding-bottom: 60px;
  background-color: ${({ theme }) => theme.colors.white};

  @media ${device.md} {
    padding-bottom: 100px;
  }
`;

const TitleContainer = styled.div`
  position: relative;
  padding: 60px 0;
  z-index: 1;

  @media ${device.md} {
    padding: 60px 0 80px;
  }
`;

const Title = styled(SectionTitle)`
  color: ${({ theme }) => theme.colors.darkPurple};
  text-align: center;
`;

const EquipmentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 35px;
  position: relative;
  padding: 0 15px;
  max-width: 360px;
  margin: 0 auto;
  z-index: 1;

  @media ${device.md} {
    padding: 0;
    max-width: 600px;
  }

  @media ${device.lg} {
    gap: 50px;
    max-width: 700px;
  }
`;

const EquipmentItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  padding-bottom: 20px;
  border-bottom: 2px solid ${({ theme }) => theme.colors.gray};

  @media ${device.md} {
    flex-direction: row;
    gap: 50px;
    padding-bottom: 30px;
  }
`;

const EquipmentIcon = styled.div`
  position: relative;
  min-width: 70px;
  height: 70px;

  & > svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const EquipmentText = styled.p`
  font-family: ${({ theme }) => theme.fonts.OpenSans};
  color: ${({ theme }) => theme.colors.darkPurple};
  font-size: 16px;
  font-weight: 500;

  @media ${device.lg} {
    font-size: 18px;
  }
`;

const StarsContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 0;

  @media ${device.lg} {
    max-width: 1280px;
    margin: 0 auto;
  }

  @media ${device.xl} {
    max-width: 1440px;
  }
`;

const StartItem = css`
  position: absolute;

  & > svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  @keyframes glass-effect {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
`;

const StarItem1 = styled.div`
  ${StartItem}
  width: 50px;
  height: 50px;
  top: 3%;
  left: 3%;
  transform: rotate(15deg);

  @media ${device.sm} {
    left: 10%;

    & > svg .glass {
      animation-name: glass-effect;
      animation-duration: 1s;
      animation-iteration-count: infinite;
      animation-direction: alternate-reverse;
    }
  }

  @media ${device.md} {
    width: 60px;
    height: 60px;
  }

  @media ${device.lg} {
    top: 8%;
  }

  @media ${device.xl} {
    width: 70px;
    height: 70px;
  }
`;

const StarItem2 = styled.div`
  ${StartItem}
  width: 40px;
  height: 40px;
  top: 12%;
  right: 3%;
  transform: rotate(-15deg);

  @media ${device.sm} {
    right: 10%;

    & > svg .glass {
      animation-name: glass-effect;
      animation-duration: 1s;
      animation-delay: 3s;
      animation-iteration-count: infinite;
      animation-direction: alternate-reverse;
    }
  }

  @media ${device.md} {
    width: 50px;
    height: 50px;
  }

  @media ${device.lg} {
    top: 18%;
  }

  @media ${device.xl} {
    width: 60px;
    height: 60px;
  }
`;

const StarItem3 = styled.div`
  display: none;

  @media ${device.sm} {
    ${StartItem}
    display: block;
    width: 45px;
    height: 45px;
    transform: rotate(45deg);
    top: 30%;
    left: 7%;

    & > svg .glass {
      animation-name: glass-effect;
      animation-duration: 1s;
      animation-delay: 1s;
      animation-iteration-count: infinite;
      animation-direction: alternate-reverse;
    }
  }

  @media ${device.md} {
    width: 55px;
    height: 55px;
    left: 5%;
  }

  @media ${device.lg} {
    top: 35%;
  }

  @media ${device.xl} {
    top: 37%;
    left: 13%;
    width: 65px;
    height: 65px;
  }
`;

const StarItem4 = styled.div`
  display: none;

  @media ${device.sm} {
    ${StartItem}
    display: block;
    width: 45px;
    height: 45px;
    transform: rotate(-60deg);
    top: 45%;
    right: 7%;

    & > svg .glass {
      animation-name: glass-effect;
      animation-duration: 1s;
      animation-delay: 4s;
      animation-iteration-count: infinite;
      animation-direction: alternate-reverse;
    }
  }

  @media ${device.md} {
    width: 55px;
    height: 55px;
    right: 5%;
  }

  @media ${device.xl} {
    right: 12%;
    width: 65px;
    height: 65px;
  }
`;

const StarItem5 = styled.div`
  display: none;

  @media ${device.sm} {
    ${StartItem}
    display: block;
    width: 50px;
    height: 50px;
    top: 65%;
    left: 7%;
    transform: rotate(70deg);

    & > svg .glass {
      animation-name: glass-effect;
      animation-duration: 1s;
      animation-delay: 2s;
      animation-iteration-count: infinite;
      animation-direction: alternate-reverse;
    }
  }

  @media ${device.md} {
    width: 60px;
    height: 60px;
    left: 5%;
  }

  @media ${device.xl} {
    top: 70%;
    left: 7%;
    width: 70px;
    height: 70px;
  }
`;

const StarItem6 = styled.div`
  display: none;

  @media ${device.sm} {
    ${StartItem}
    display: block;
    width: 40px;
    height: 40px;
    top: 75%;
    right: 7%;
    transform: rotate(-90deg);

    & > svg .glass {
      animation-name: glass-effect;
      animation-duration: 1s;
      animation-delay: 5s;
      animation-iteration-count: infinite;
      animation-direction: alternate-reverse;
    }
  }

  @media ${device.md} {
    width: 50px;
    height: 50px;
    right: 5%;
  }

  @media ${device.xl} {
    width: 60px;
    height: 60px;
  }
`;

const Equipment = () => {
  const { t } = useTranslation();

  return (
    <Container id="equipment">
      <TitleContainer>
        <Title>{t("equipment:title")}</Title>
      </TitleContainer>
      <EquipmentContainer>
        <EquipmentItem>
          <EquipmentIcon>
            <SpeakersSvg />
          </EquipmentIcon>
          <EquipmentText
            dangerouslySetInnerHTML={{
              __html: t("equipment:speakers", {
                interpolation: { escapeValue: false },
              }),
            }}
          ></EquipmentText>
        </EquipmentItem>
        <EquipmentItem>
          <EquipmentIcon>
            <DiscoballSvg />
          </EquipmentIcon>
          <EquipmentText
            dangerouslySetInnerHTML={{
              __html: t("equipment:laserSystem", {
                interpolation: { escapeValue: false },
              }),
            }}
          ></EquipmentText>
        </EquipmentItem>
        <EquipmentItem>
          <EquipmentIcon>
            <SmokemakerSvg />
          </EquipmentIcon>
          <EquipmentText
            dangerouslySetInnerHTML={{
              __html: t("equipment:smokeMaker", {
                interpolation: { escapeValue: false },
              }),
            }}
          ></EquipmentText>
        </EquipmentItem>
      </EquipmentContainer>
      <StarsContainer>
        <StarItem1>
          <StarSvg />
        </StarItem1>
        <StarItem2>
          <StarSvg />
        </StarItem2>
        <StarItem3>
          <StarSvg />
        </StarItem3>
        <StarItem4>
          <StarSvg />
        </StarItem4>
        <StarItem5>
          <StarSvg />
        </StarItem5>
        <StarItem6>
          <StarSvg />
        </StarItem6>
      </StarsContainer>
    </Container>
  );
};

export default Equipment;
