import { FC, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import pl from "date-fns/locale/pl";
import { styled } from "styled-components";
import { device } from "../styles/Breakpoints";
registerLocale("pl", pl);

const FormItem = styled.div`
  position: relative;
  width: 100%;
`;

const FormLabel = styled.label<{ $isFocused: boolean }>`
  position: absolute;
  left: 0;
  top: 10px;
  background-color: ${({ theme }) => theme.colors.lightGray};
  z-index: 10;
  font-family: ${({ theme }) => theme.fonts.OpenSans};
  font-size: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.darkPurple};
  transition: transform 150ms ease-out, font-size 150ms ease-out;
  pointer-events: none;

  ${({ $isFocused }) =>
    $isFocused &&
    `
    transform: translateY(-125%);
    font-size: .75em!important;
  `}

  @media ${device.lg} {
    font-size: 18px;
  }
`;

const FormError = styled.p`
  color: ${({ theme }) => theme.colors.red};
  font-family: ${({ theme }) => theme.fonts.OpenSans};
  font-size: 14px;
  font-weight: 500;
  padding-top: 10px;
`;

interface Props {
  id: string;
  value: Date | null;
  error?: string;
  onChange?: (id: string, value: Date | null) => void;
  setFieldError?: (id: string, value: any) => void;
}

const DatePickerField: FC<Props> = ({
  id,
  value,
  error,
  onChange,
  setFieldError,
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleChange = (val: Date | null) => {
    onChange?.(id, val);
    setFieldError?.(id, undefined);
  };

  return (
    <FormItem>
      <FormLabel $isFocused={isFocused}>Data imprezy</FormLabel>
      <DatePicker
        locale="pl"
        selected={(value && new Date(value)) || null}
        onChange={(val) => handleChange(val)}
        onFocus={() => setIsFocused(true)}
        onBlur={(e) => {
          const inputValue = e.target.value;
          if (inputValue === "") {
            setIsFocused(false);
          }
        }}
        className="date-picker-input"
      />
      {!!error && <FormError>{error}</FormError>}
    </FormItem>
  );
};

export default DatePickerField;
