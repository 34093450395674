import { FC, useEffect, useRef, useState } from "react";
import { styled } from "styled-components";
import ArrowDownSvg from "./icons/ArrowDownSvg";
import { device } from "../styles/Breakpoints";

const FaqItem = styled.div<{
  $isExpanded: boolean;
  $initialMaxHeight: number;
  $finalMaxHeight: number;
}>`
  position: relative;
  overflow: hidden;
  border-bottom: 2px solid ${({ theme }) => theme.colors.gray};
  max-height: ${({ $initialMaxHeight }) =>
    $initialMaxHeight && `${$initialMaxHeight}px`};
  will-change: max-height;
  transform: translate(0, 0);
  transition: 0.6s max-height;

  ${({ $isExpanded, $finalMaxHeight }) =>
    $isExpanded &&
    `
    max-height: ${$finalMaxHeight}px;
    opacity: 1;
    `}
`;

const Heading = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  cursor: pointer;
`;

const Question = styled.h3`
  color: ${({ theme }) => theme.colors.darkPurple};
  font-family: ${({ theme }) => theme.fonts.OpenSans};
  font-size: 16px;
  font-weight: 500;
  padding-right: 20px;

  @media ${device.lg} {
    font-size: 18px;
  }
`;

const Icon = styled.div<{ $isExpanded: boolean }>`
  flex-shrink: 0;
  position: relative;
  width: 12px;
  height: 17px;
  transform: rotate(0deg);
  transition: transform 0.4s ease;

  & > svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  ${({ $isExpanded }) => $isExpanded && `transform: rotate(-180deg);`}

  @media ${device.lg} {
    width: 14px;
    height: 19px;
  }
`;

const Answer = styled.p`
  color: ${({ theme }) => theme.colors.darkPurple};
  font-family: ${({ theme }) => theme.fonts.OpenSans};
  font-size: 14px;
  font-weight: 500;
  padding-bottom: 20px;

  @media ${device.lg} {
    font-size: 16px;
  }
`;

interface Props {
  question: string;
  answer: string;
}

const Faq: FC<Props> = ({ question, answer }) => {
  const [questionHeight, setQuestionHeight] = useState(0);
  const [answerHeight, setAnswerHeight] = useState(0);
  const [isExpanded, setIsExpanded] = useState(false);
  const questionRef = useRef<HTMLDivElement>(null);
  const answerRef = useRef<HTMLParagraphElement>(null);

  const onToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const resize = () => {
    if (questionRef.current) {
      setQuestionHeight(questionRef.current.clientHeight);
    }

    if (answerRef.current) {
      setAnswerHeight(answerRef.current.clientHeight);
    }
  };

  useEffect(() => {
    resize();

    window.addEventListener("resize", resize);
  }, []);

  return (
    <FaqItem
      $isExpanded={isExpanded}
      $initialMaxHeight={questionHeight}
      $finalMaxHeight={questionHeight + answerHeight}
    >
      <Heading ref={questionRef} onClick={onToggle}>
        <Question>{question}</Question>
        <Icon $isExpanded={isExpanded}>
          <ArrowDownSvg />
        </Icon>
      </Heading>
      <Answer ref={answerRef}>{answer}</Answer>
    </FaqItem>
  );
};

export default Faq;
