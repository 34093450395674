import { SVGProps } from "react";

const SmokemakerSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="98"
    height="92"
    viewBox="0 0 98 92"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M45.6207 12.8035H1.68973C0.756629 12.8035 0 13.5094 0 14.3803V77.4567C0 78.3276 0.756629 79.0337 1.68973 79.0337H45.6207C46.5538 79.0337 47.3105 78.3276 47.3105 77.4567V14.3803C47.3103 13.5094 46.5538 12.8035 45.6207 12.8035ZM43.931 75.8799H3.37928V15.9573H43.931V75.8799Z"
      fill="#2A1B3D"
    />
    <path
      d="M96.3102 12.8035H52.3792C51.4461 12.8035 50.6895 13.5096 50.6895 14.3805V77.4569C50.6895 78.3277 51.4461 79.0339 52.3792 79.0339H96.3102C97.2433 79.0339 97.9999 78.3277 97.9999 77.4569V14.3803C97.9999 13.5094 97.2433 12.8035 96.3102 12.8035ZM94.6206 75.8799H54.0689V15.9573H94.6206V75.8799Z"
      fill="#2A1B3D"
    />
    <path
      d="M23.6075 22.3267C18.949 22.3267 15.1592 25.8638 15.1592 30.2112C15.1592 34.5587 18.949 38.0958 23.6075 38.0958C28.2659 38.0958 32.0558 34.5589 32.0558 30.2112C32.0558 25.8638 28.2661 22.3267 23.6075 22.3267ZM23.6075 34.942C20.8122 34.942 18.5385 32.8198 18.5385 30.2112C18.5385 27.6028 20.8122 25.4805 23.6075 25.4805C26.4028 25.4805 28.6765 27.6026 28.6765 30.2112C28.6765 32.8197 26.4028 34.942 23.6075 34.942Z"
      fill="#2A1B3D"
    />
    <path
      d="M74.2974 22.3267C69.639 22.3267 65.8491 25.8638 65.8491 30.2112C65.8491 34.5587 69.639 38.0958 74.2974 38.0958C78.9558 38.0958 82.7457 34.5589 82.7457 30.2112C82.7457 25.8638 78.9558 22.3267 74.2974 22.3267ZM74.2974 34.942C71.5021 34.942 69.2284 32.8198 69.2284 30.2112C69.2284 27.6028 71.5021 25.4805 74.2974 25.4805C77.0925 25.4805 79.3664 27.6026 79.3664 30.2112C79.3664 32.8197 77.0925 34.942 74.2974 34.942Z"
      fill="#2A1B3D"
    />
    <path
      d="M23.6076 44.4033C16.1543 44.4033 10.0903 50.0625 10.0903 57.0187C10.0903 63.9749 16.1543 69.634 23.6076 69.634C31.061 69.634 37.1249 63.9749 37.1249 57.0187C37.1249 50.0625 31.061 44.4033 23.6076 44.4033ZM23.6076 66.4801C18.0176 66.4801 13.4696 62.2357 13.4696 57.0185C13.4696 51.8013 18.0176 47.5569 23.6076 47.5569C29.1977 47.5569 33.7457 51.8013 33.7457 57.0185C33.7457 62.2359 29.1977 66.4801 23.6076 66.4801Z"
      fill="#2A1B3D"
    />
    <path
      d="M74.2976 44.4033C66.8442 44.4033 60.7803 50.0625 60.7803 57.0187C60.7803 63.9749 66.8442 69.634 74.2976 69.634C81.7509 69.634 87.8149 63.9749 87.8149 57.0187C87.8149 50.0625 81.7509 44.4033 74.2976 44.4033ZM74.2976 66.4801C68.7075 66.4801 64.1596 62.2357 64.1596 57.0185C64.1596 51.8013 68.7075 47.5569 74.2976 47.5569C79.8876 47.5569 84.4356 51.8013 84.4356 57.0185C84.4354 62.2359 79.8876 66.4801 74.2976 66.4801Z"
      fill="#2A1B3D"
    />
  </svg>
);
export default SmokemakerSvg;
