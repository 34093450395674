import { SVGProps } from "react";

const LasersSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="500"
    height="500"
    viewBox="0 0 500 500"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_223_13)">
      <rect
        className="line1"
        x="-6"
        y="500.162"
        width="461.842"
        height="7"
        rx="3.5"
        transform="rotate(-63.7357 -6 500.162)"
        fill="url(#paint0_linear_223_13)"
      />
      <rect
        className="line2"
        x="-6"
        y="503.171"
        width="480.354"
        height="7"
        rx="3.5"
        transform="rotate(-46.5294 -6 503.171)"
        fill="url(#paint1_linear_223_13)"
      />
      <rect
        className="line3"
        x="-11"
        y="505.915"
        width="519.496"
        height="7"
        rx="3.5"
        transform="rotate(-37.4536 -11 505.915)"
        fill="url(#paint2_linear_223_13)"
      />
      <rect
        className="line4"
        x="-4"
        y="499.311"
        width="516.653"
        height="7"
        rx="3.5"
        transform="rotate(-33.709 -4 499.311)"
        fill="url(#paint3_linear_223_13)"
      />
      <rect
        className="line5"
        x="-5"
        y="502.778"
        width="461.842"
        height="7"
        rx="3.5"
        transform="rotate(-41.2936 -5 502.778)"
        fill="url(#paint4_linear_223_13)"
      />
      <rect
        className="line6"
        x="-11"
        y="513.167"
        width="487.227"
        height="7"
        rx="3.5"
        transform="rotate(-51.4733 -11 513.167)"
        fill="url(#paint5_linear_223_13)"
      />
      <rect
        className="line7"
        x="-6"
        y="506.885"
        width="461.842"
        height="7"
        rx="3.5"
        transform="rotate(-58.0517 -6 506.885)"
        fill="url(#paint6_linear_223_13)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_223_13"
        x1="0.222422"
        y1="503.662"
        x2="455.842"
        y2="503.662"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF0000" />
        <stop offset="1" stopColor="#FF0000" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_223_13"
        x1="0.471844"
        y1="506.671"
        x2="474.354"
        y2="506.672"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00FFD1" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_223_13"
        x1="-4.0008"
        y1="509.415"
        x2="508.496"
        y2="509.415"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF9900" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_223_13"
        x1="2.96089"
        y1="502.811"
        x2="512.653"
        y2="502.811"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FAFF00" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_223_13"
        x1="1.22242"
        y1="506.277"
        x2="456.842"
        y2="506.278"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#05FF00" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_223_13"
        x1="-4.43556"
        y1="516.666"
        x2="476.227"
        y2="516.667"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#AD00FF" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_223_13"
        x1="0.222422"
        y1="510.385"
        x2="455.842"
        y2="510.385"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF00B8" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <clipPath id="clip0_223_13">
        <rect width="500" height="500" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default LasersSvg;
