import { DefaultTheme } from "styled-components";

const theme: DefaultTheme = {
  colors: {
    white: "#ffffff",
    primary: "#1e1d1d",
    secondary: "#FAFAFA",
    primaryText: "#1e1d1d",
    secondaryText: "#1e1d1d",
    placeholder: "#eaeaea",
    gray: "#A4B3B6",
    lightGray: "#eeeeee",
    purple: "#8265A7",
    darkPurple: "#2A1B3D",
    pink: "#D83F87",
    red: "#7a1721",
    focused: "#4287f5",
    btnBg: "#8265A7",
    btnBgHover: "#D83F87",
    headerBg: "#211531",
  },
  fonts: {
    PaytoneOne: "Paytone One",
    OpenSans: "Open Sans",
  },
};

export { theme };
