import { SVGProps } from "react";

const StarSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="130"
    height="130"
    viewBox="0 0 130 130"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_220_3)">
      <path
        d="M64.7901 6.10844L78.985 48.9202L125.169 48.999L87.7579 75.3794L101.953 118.191L64.6363 91.6833L27.2247 118.064L41.5734 75.3006L4.25695 48.7928L50.4414 48.8715L64.7901 6.10844Z"
        fill="url(#paint0_linear_220_3)"
      />
      <rect
        className="glass"
        width="130"
        height="130"
        fill="url(#paint1_linear_220_3)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_220_3"
        x1="64.7901"
        y1="6.10844"
        x2="64.5789"
        y2="129.954"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#44318D" />
        <stop offset="1" stopColor="#44318D" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_220_3"
        x1="65"
        y1="0"
        x2="65"
        y2="130"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <clipPath id="clip0_220_3">
        <rect width="130" height="130" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default StarSvg;
