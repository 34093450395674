import useEmblaCarousel from "embla-carousel-react";
import { styled } from "styled-components";
import { device } from "../styles/Breakpoints";

const Embla = styled.div``;

const EmblaViewport = styled.div`
  position: relative;
  overflow: hidden;
`;

const EmblaContainer = styled.div`
  backface-visibility: hidden;
  display: flex;
  gap: 30px;
  touch-action: pan-y;
`;

const EmblaSlide = styled.div`
  flex: 0 0 300px;
  min-width: 0;
  position: relative;
  display: flex;
  justify-content: center;

  &:nth-child(1) {
    margin-left: 30px;
  }

  &:nth-child(3) {
    margin-right: 30px;
  }

  @media ${device.sm} {
    flex: 0 0 350px;
  }

  @media ${device.md} {
    flex: 0 0 33%;
  }

  @media ${device.xl} {
    flex: 0 0 33%;
  }
`;

const ImageContainer = styled.div<{ $src?: string }>`
  position: relative;
  width: 100%;
  height: 300px;
  border-radius: 30px;
  overflow: hidden;
  background: ${({ $src }) => `url(${$src})`};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @media ${device.lg} {
    height: 400px;
    max-width: 450px;
  }

  @media ${device.xl} {
    height: 500px;
    max-width: 550px;
  }
`;

const IMAGES_LIST = [
  "assets/carousel/image1.webp",
  "assets/carousel/image2.webp",
  "assets/carousel/image3.webp",
];

const Videos = () => {
  const [emblaRef] = useEmblaCarousel({
    align: "center",
    containScroll: "keepSnaps",
  });

  return (
    <div>
      <Embla>
        <EmblaViewport ref={emblaRef}>
          <EmblaContainer>
            {IMAGES_LIST.map((image_url, index) => (
              <EmblaSlide key={index}>
                <ImageContainer $src={image_url} />
              </EmblaSlide>
            ))}
          </EmblaContainer>
        </EmblaViewport>
      </Embla>
    </div>
  );
};

export default Videos;
