import { styled } from "styled-components";
import { device } from "../styles/Breakpoints";
import { useTranslation } from "react-i18next";
import FacebookSvg from "./icons/FacebookSvg";
import TiktokSvg from "./icons/TiktokSvg";
import PhoneSvg from "./icons/PhoneSvg";
import MailSvg from "./icons/MailSvg";

const FooterContainer = styled.footer`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 20px 0;
`;

const FooterContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  padding: 0 15px;

  @media ${device.sm} {
    padding: 0 60px;
    align-items: unset;
    gap: 15px;
  }

  @media (min-width: 700px) {
    gap: unset;
  }

  @media ${device.md} {
    flex-direction: row;
  }

  @media ${device.lg} {
    max-width: 1280px;
    margin: 0 auto;
  }

  @media ${device.xl} {
    max-width: 1440px;
  }
`;

const CopyrightText = styled.p`
  align-self: flex-start;
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.OpenSans};
  font-size: 16px;
  font-weight: 500;

  @media (min-width: 390px) {
    align-self: flex-end;
  }

  @media ${device.md} {
    align-self: center;
  }
`;

const FooterInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;

  @media (min-width: 390px) {
    flex-direction: row;
    align-items: center;
  }

  @media ${device.sm} {
    flex-direction: row;
    width: unset;
    gap: 30px;
  }

  @media ${device.md} {
    justify-content: unset;
  }
`;

const ContactInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media (min-width: 700px) {
    flex-direction: row;
  }

  @media ${device.md} {
    gap: 30px;
  }
`;

const ContactInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

const ContactInfoIcon = styled.div<{ $isPhoneIcon?: boolean }>`
  position: relative;
  height: 18px;

  & > svg {
    width: 100%;
    height: 100%;
  }

  ${({ $isPhoneIcon }) => $isPhoneIcon && `padding: 0 3px`}
`;

const ContactInfoText = styled.a`
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.OpenSans};
  font-size: 16px;
  font-weight: 500;
`;

const SocialMediaContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const SocialMedia = styled.a`
  position: relative;
  width: 50px;
  height: 50px;

  & > svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &:hover {
    & > svg .background {
      transition: 0.3s ease;
      fill: ${({ theme }) => theme.colors.pink};
    }
  }
`;

const Footer = () => {
  const { t } = useTranslation();

  return (
    <FooterContainer>
      <FooterContent>
        <FooterInfo>
          <SocialMediaContainer>
            <SocialMedia
              href={process.env.REACT_APP_FACEBOOK_LINK}
              target="_blank"
            >
              <FacebookSvg />
            </SocialMedia>
            <SocialMedia
              href={process.env.REACT_APP_TIKTOK_LINK}
              target="_blank"
            >
              <TiktokSvg />
            </SocialMedia>
          </SocialMediaContainer>
          <ContactInfoContainer>
            <ContactInfo>
              <ContactInfoIcon $isPhoneIcon={true}>
                <PhoneSvg />
              </ContactInfoIcon>
              <ContactInfoText href="tel:+48578450212" target="_blank">
                {t("footer:phoneNumber")}
              </ContactInfoText>
            </ContactInfo>
            <ContactInfo>
              <ContactInfoIcon>
                <MailSvg />
              </ContactInfoIcon>
              <ContactInfoText
                href="mailto:disconamiot@gmail.com"
                target="_blank"
              >
                {t("footer:mail")}
              </ContactInfoText>
            </ContactInfo>
          </ContactInfoContainer>
        </FooterInfo>
        <CopyrightText>{t("footer:copyrights")}</CopyrightText>
      </FooterContent>
    </FooterContainer>
  );
};

export default Footer;
