import { SVGProps } from "react";

const SuitcaseSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="49"
    height="49"
    viewBox="0 0 49 49"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.375 12.2501C18.375 9.99492 20.2032 8.16675 22.4583 8.16675H26.5417C28.7969 8.16675 30.625 9.99492 30.625 12.2501V16.3334H18.375V12.2501Z"
      stroke="#2A1B3D"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M36.7498 16.3333H12.2498C9.99467 16.3333 8.1665 18.1614 8.1665 20.4166V34.7083C8.1665 36.9634 9.99467 38.7916 12.2498 38.7916H36.7498C39.005 38.7916 40.8332 36.9634 40.8332 34.7083V20.4166C40.8332 18.1614 39.005 16.3333 36.7498 16.3333Z"
      stroke="#2A1B3D"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SuitcaseSvg;
