import { createGlobalStyle } from "styled-components";
import { device } from "./Breakpoints";

export const GlobalStyles = createGlobalStyle`
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-size: 100%;
}

body {
  margin: 0;
  padding: 0;
  background-color: #2A1B3D;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
figure,
blockquote,
dl,
dd {
  padding: 0;
  margin: 0;
}

button {
  border: none;
  background-color: transparent;
  font-family: inherit;
  padding: 0;
  cursor: pointer;
}

a {
  text-decoration: none;
  color: inherit;
}

ul[role="list"],
ol[role="list"] {
  list-style: none;
}

li {
  list-style-type: none;
}

img,
picture {
  max-width: 100%;
  display: block;
}

input {
  border: 0;
  outline: 0;
}

input:focus
{
	outline:0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:active{
    -webkit-background-clip: text;
    -webkit-text-fill-color: #2A1B3D;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: 0 2px 0px -1px #2A1B3D;
}

input[data-autocompleted] {
    background-color: transparent !important;
}

.react-datepicker-popper {
  z-index: 999;
}

.react-datepicker-wrapper {
  width: 100%;
}

.date-picker-input {
  position: relative;
  padding: 12px 0px 5px;
  width: 100%;
  font-family: ${({ theme }) => theme.fonts.OpenSans};
  font-size: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.darkPurple};
  box-shadow: 0 1px 0 0 ${({ theme }) => theme.colors.darkPurple};
  background-color: ${({ theme }) => theme.colors.lightGray};
  transition: box-shadow 150ms ease-out;

  &:focus {
    box-shadow: 0 2px 0 0 ${({ theme }) => theme.colors.darkPurple};
  }

  @media ${device.lg} {
    font-size: 18px;
  }
}
`;
