import i18next from "i18next";
import { initReactI18next } from "react-i18next";

import pl from "./pl/pl_PL";

const resources = {
  pl,
};

i18next.use(initReactI18next).init({
  resources,
  lng: "pl",
  fallbackLng: "pl",
});

export default i18next;
