import { SVGProps } from "react";

const TiktokSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="90"
    height="90"
    viewBox="0 0 90 90"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M45 82.5C65.7107 82.5 82.5 65.7107 82.5 45C82.5 24.2893 65.7107 7.5 45 7.5C24.2893 7.5 7.5 24.2893 7.5 45C7.5 65.7107 24.2893 82.5 45 82.5Z"
      fill="white"
      className="background"
    />
    <path
      d="M61.8135 34.0736C59.6365 33.6144 57.6651 32.4814 56.1868 30.8399C54.7084 29.1984 53.8004 27.1341 53.5952 24.948V24H46.0853V53.391C46.0806 54.6921 45.6627 55.9592 44.8901 57.014C44.1176 58.0688 43.0293 58.8585 41.7782 59.2719C40.5272 59.6854 39.1761 59.7019 37.9151 59.3191C36.654 58.9364 35.5462 58.1736 34.7474 57.1379C33.9362 56.0849 33.4862 54.8045 33.4627 53.4824C33.4392 52.1602 33.8433 50.865 34.6165 49.7846C35.3898 48.7042 36.4919 47.8948 37.763 47.4737C39.0342 47.0526 40.4083 47.0417 41.6861 47.4427V39.9187C38.8366 39.5302 35.9353 40.0298 33.3879 41.3475C30.8405 42.6652 28.7745 44.7352 27.4788 47.2679C26.1831 49.8006 25.7226 52.6693 26.1617 55.4727C26.6007 58.2762 27.9174 60.8743 29.9276 62.9035C31.8445 64.8377 34.3024 66.1647 36.9876 66.7152C39.6728 67.2656 42.4634 67.0145 45.0032 65.994C47.5431 64.9734 49.7169 63.2296 51.2472 60.9852C52.7776 58.7408 53.595 56.0977 53.5952 53.3931V38.3752C56.6302 40.5149 60.2692 41.6622 64 41.6556V34.2972C63.2653 34.298 62.5324 34.2231 61.8135 34.0736Z"
      fill="#2A1B3D"
    />
  </svg>
);
export default TiktokSvg;
