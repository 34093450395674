import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "styled-components";
import { device } from "../styles/Breakpoints";
import { useFormik } from "formik";
import {
  ContactFormType,
  defaultValuesContactForm,
  contactFormValidationSchema,
} from "../utils/ContactFormUtils";

// COMPONENTS
import { SectionTitle } from "../components/Text";
import Input from "../components/Input";
import DatePickerField from "../components/DatePickerField";
import Checkbox from "../components/Checkbox";
import Button from "../components/Button";
import SuccessSvg from "../components/icons/SuccessSvg";

const Container = styled.section`
  position: relative;
  padding-bottom: 60px;

  @media ${device.sm} {
    background-image: url("./assets/contact_bg.svg");
    background-repeat: no-repeat;
    background-size: cover;
  }

  @media ${device.md} {
    padding-bottom: 100px;
  }
`;

const TitleContainer = styled.div`
  position: relative;
  padding: 60px 15px;

  @media ${device.md} {
    padding: 60px 0 80px;
  }
`;

const Title = styled(SectionTitle)`
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
`;

const ContactContainer = styled.div`
  position: relative;
  padding: 0 15px;
  max-width: 360px;
  margin: 0 auto;

  @media ${device.md} {
    max-width: 600px;
    padding: 0px;
  }

  @media ${device.xl} {
    max-width: 800px;
  }
`;

const ContactFormContainer = styled.div``;

const ContactForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding: 30px 15px;
  border-radius: 30px;
  background-color: ${({ theme }) => theme.colors.lightGray};

  @media ${device.md} {
    padding: 50px 30px 40px;
  }

  @media ${device.lg} {
    gap: 30px;
  }

  @media ${device.xl} {
    padding: 50px 60px 50px;
    border-radius: 60px;
  }
`;

const FieldsGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;

  @media ${device.md} {
    flex-direction: row;
  }

  @media ${device.xl} {
    gap: 50px;
  }
`;

const CheckboxGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: 30px;
  flex: 1;
  align-items: flex-end;
`;

const EmptyDiv = styled.div`
  display: none;

  @media ${device.md} {
    display: block;
    position: relative;
    width: 100%;
  }
`;

const FormSuccessful = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;
  position: relative;
  width: 100%;
  height: 777px;
  padding: 30px 15px;
  border-radius: 30px;
  background-color: ${({ theme }) => theme.colors.lightGray};

  @media ${device.md} {
    width: 600px;
    height: 531px;
    padding: 50px 30px 40px;
  }

  @media ${device.lg} {
    width: 600px;
    height: 571px;
  }

  @media ${device.xl} {
    width: 800px;
    height: 590px;
    padding: 50px 60px 50px;
    border-radius: 60px;
  }
`;

const FormSuccessfulText = styled.h2`
  font-family: ${({ theme }) => theme.fonts.OpenSans};
  color: ${({ theme }) => theme.colors.darkPurple};
  font-size: 24px;
  font-weight: 500;
  text-align: center;

  @media ${device.lg} {
    font-size: 32px;
  }
`;

const Contact = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [isFormSuccessful, setIsFormSuccessful] = useState(false);

  const sendEmail = async (data: any) => {
    setLoading(true);
    const {
      name,
      surname,
      email,
      phone,
      locality,
      address1,
      address2,
      zipCode,
      dateEvent,
      rental,
    } = data;
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    const formattedDate = dateEvent.toLocaleDateString("pl-PL", options);

    try {
      const response = await fetch("/api/contact", {
        method: "POST",
        headers: {
          Accept: "application.json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: name,
          surname: surname,
          email: email,
          dateEvent: formattedDate,
          phone: phone,
          locality: locality,
          address1: address1,
          address2: address2,
          zipCode: zipCode,
          rental: rental,
        }),
      });

      const data = await response.json();
      console.log(data);
      setIsFormSuccessful(true);
    } catch (error) {
      console.log("There was an error", error);
    } finally {
      setLoading(false);
    }
  };

  const {
    values,
    errors,
    setFieldValue,
    handleSubmit,
    setFieldError,
    isSubmitting,
    setSubmitting,
  } = useFormik<ContactFormType>({
    initialValues: defaultValuesContactForm,
    onSubmit: (values, { resetForm, setStatus }) => {
      sendEmail(values);
      setSubmitting(false);
      resetForm();
      setStatus({ success: "Email sent !" });
    },
    validateOnChange: false,
    validationSchema: contactFormValidationSchema,
  });

  return (
    <Container id="contact">
      <TitleContainer>
        <Title>{t("contact:title")}</Title>
      </TitleContainer>
      <ContactContainer>
        <ContactFormContainer>
          {!isFormSuccessful ? (
            <ContactForm onSubmit={handleSubmit}>
              <FieldsGroup>
                <Input
                  id="name"
                  label={t("contact:form:name")}
                  value={values.name}
                  error={errors.name}
                  onChange={setFieldValue}
                  setFieldError={setFieldError}
                  required
                />
                <Input
                  id="surname"
                  label={t("contact:form:surname")}
                  value={values.surname}
                  error={errors.surname}
                  onChange={setFieldValue}
                  setFieldError={setFieldError}
                  required
                />
              </FieldsGroup>
              <FieldsGroup>
                <Input
                  id="email"
                  label={t("contact:form:email")}
                  value={values.email}
                  error={errors.email}
                  onChange={setFieldValue}
                  setFieldError={setFieldError}
                  required
                />
                <Input
                  id="phone"
                  label={t("contact:form:phone")}
                  value={values.phone}
                  error={errors.phone}
                  onChange={setFieldValue}
                  setFieldError={setFieldError}
                />
              </FieldsGroup>
              <FieldsGroup>
                <Input
                  id="locality"
                  label={t("contact:form:locality")}
                  value={values.locality}
                  error={errors.locality}
                  onChange={setFieldValue}
                  setFieldError={setFieldError}
                />
                <Input
                  id="zipCode"
                  label={t("contact:form:zipCode")}
                  value={values.zipCode}
                  error={errors.zipCode}
                  onChange={setFieldValue}
                  setFieldError={setFieldError}
                />
              </FieldsGroup>
              <FieldsGroup>
                <Input
                  id="address1"
                  label={t("contact:form:address1")}
                  value={values.address1}
                  error={errors.address1}
                  onChange={setFieldValue}
                  setFieldError={setFieldError}
                />
                <Input
                  id="address2"
                  label={t("contact:form:address2")}
                  value={values.address2}
                  error={errors.address2}
                  onChange={setFieldValue}
                  setFieldError={setFieldError}
                />
              </FieldsGroup>
              <FieldsGroup>
                <DatePickerField
                  id="dateEvent"
                  value={values.dateEvent}
                  error={errors.dateEvent}
                  onChange={setFieldValue}
                  setFieldError={setFieldError}
                />
                <EmptyDiv></EmptyDiv>
              </FieldsGroup>
              <CheckboxGroup>
                <Checkbox
                  id="rental"
                  name="rentalType"
                  label={t("contact:form:rental:individual")}
                  value={t("contact:form:rental:individual")}
                  checked={
                    values.rental === t("contact:form:rental:individual")
                  }
                  onChange={setFieldValue}
                />
                <Checkbox
                  id="rental"
                  name="rentalType"
                  label={t("contact:form:rental:company")}
                  value={t("contact:form:rental:company")}
                  checked={values.rental === t("contact:form:rental:company")}
                  onChange={setFieldValue}
                />
              </CheckboxGroup>
              <Button
                title={t("contact:form:send")}
                disabled={isSubmitting}
                isLoading={loading}
                isSubmitBtn
              />
            </ContactForm>
          ) : (
            <FormSuccessful>
              <SuccessSvg />
              <FormSuccessfulText>
                {t("contact:form:successful")}
              </FormSuccessfulText>
            </FormSuccessful>
          )}
        </ContactFormContainer>
      </ContactContainer>
    </Container>
  );
};

export default Contact;
