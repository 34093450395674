import { SVGProps } from "react";

const TruckSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="46"
    height="46"
    viewBox="0 0 46 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_62_28)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.75 5.75C2.57437 5.75 0 8.32437 0 11.5V28.75C0 31.2649 1.61453 33.4027 3.86364 34.1834C4.18669 37.5874 7.05322 40.25 10.5417 40.25C13.9212 40.25 16.7171 37.7509 17.1821 34.5H28.8179C29.283 37.7509 32.0789 40.25 35.4583 40.25C38.9469 40.25 41.8132 37.5874 42.1364 34.1834C44.3854 33.4027 46 31.2649 46 28.75V20.607C46 19.2142 45.4944 17.8688 44.5773 16.8206L39.9629 11.5469C38.871 10.2991 37.2937 9.58333 35.6356 9.58333H32.2562C31.4669 7.35005 29.3369 5.75 26.8333 5.75H5.75ZM7.66667 33.4192V33.6641C7.73084 35.1965 8.99346 36.4192 10.5417 36.4192C12.0899 36.4192 13.3525 35.1965 13.4167 33.6641V33.4192C13.3525 31.8868 12.0899 30.6642 10.5417 30.6642C8.99346 30.6642 7.73084 31.8868 7.66667 33.4192ZM10.5417 26.8333C13.2177 26.8333 15.5278 28.4002 16.6044 30.6667H29.3955C30.4721 28.4002 32.7823 26.8333 35.4583 26.8333C37.997 26.8333 40.2061 28.2434 41.3456 30.323C41.842 29.9767 42.1667 29.4013 42.1667 28.75V21.0833H32.5833C30.4662 21.0833 28.75 19.3671 28.75 17.25V11.5C28.75 10.4415 27.8919 9.58333 26.8333 9.58333H5.75C4.69146 9.58333 3.83333 10.4415 3.83333 11.5V28.75C3.83333 29.4013 4.15811 29.9767 4.65453 30.323C5.79393 28.2434 8.00314 26.8333 10.5417 26.8333ZM32.5833 13.4167V15.3333C32.5833 16.3919 33.4414 17.25 34.5 17.25H39.8594L37.0779 14.0712C36.7141 13.6553 36.1882 13.4167 35.6356 13.4167H32.5833ZM32.5833 33.4192V33.6641C32.6475 35.1965 33.91 36.4192 35.4583 36.4192C37.0066 36.4192 38.2691 35.1965 38.3333 33.6641V33.4192C38.2691 31.8868 37.0066 30.6642 35.4583 30.6642C33.91 30.6642 32.6475 31.8868 32.5833 33.4192Z"
        fill="#2A1B3D"
      />
    </g>
    <defs>
      <clipPath id="clip0_62_28">
        <rect width="46" height="46" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default TruckSvg;
