import { FC, SVGProps } from "react";
import { styled } from "styled-components";

const Svg = styled.svg<{ $isChecked: boolean }>`
  stroke: #d83f87;
  stroke-width: 3px;
  height: 0;
  width: calc(21px * 0.6);
  position: absolute;
  left: calc(22px * 0.25);
  top: calc(22px * 0.19);
  stroke-dasharray: 33;

  ${({ $isChecked }) =>
    $isChecked &&
    `
    height: calc(25px * 0.6);
    animation: drawCheckbox ease-in-out 0.2s forwards;

    @keyframes drawCheckbox {
        0% {
            stroke-dashoffset: 33;
        }
        100% {
            stroke-dashoffset: 0;
        }
    }
  `}
`;

interface Props {
  props?: SVGProps<SVGSVGElement>;
  checked: boolean;
}

const CheckmarkSvg: FC<Props> = ({ props, checked }) => (
  <Svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    $isChecked={checked}
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeMiterlimit="10"
      fill="none"
      d="M22.9 3.7l-15.2 16.6-6.6-7.1"
    ></path>
  </Svg>
);

export default CheckmarkSvg;
