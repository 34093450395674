import * as yup from "yup";
import i18n from "../assets/translations/i18n";

export type ContactFormType = {
  name: string;
  surname: string;
  email: string;
  dateEvent: Date | null;
  phone: string;
  locality: string;
  address1: string;
  address2: string;
  zipCode: string;
  rental: string | null;
};

export const defaultValuesContactForm: ContactFormType = {
  name: "",
  surname: "",
  email: "",
  dateEvent: null,
  phone: "",
  locality: "",
  address1: "",
  address2: "",
  zipCode: "",
  rental: "Osoba prywatna",
};

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const contactFormValidationSchema = yup.object({
  name: yup.string().required(i18n.t("contact:errors:requiredField")),
  surname: yup.string().required(i18n.t("contact:errors:requiredField")),
  email: yup
    .string()
    .email(i18n.t("contact:errors:invalidEmail"))
    .required(i18n.t("contact:errors:requiredField")),
  phone: yup
    .string()
    .matches(phoneRegExp, i18n.t("contact:errors:invalidPhone"))
    .required(i18n.t("contact:errors:requiredField")),
  locality: yup.string().required(i18n.t("contact:errors:requiredField")),
  dateEvent: yup.string().required(i18n.t("contact:errors:requiredField")),
});
