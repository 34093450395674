import { SVGProps } from "react";

const PhoneSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="60"
    height="60"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.5 13.75C7.5 35.151 24.849 52.5 46.25 52.5C47.2155 52.5 48.1728 52.4647 49.1208 52.3952C50.2085 52.3155 50.7523 52.2758 51.2475 51.9908C51.6575 51.7548 52.0462 51.3363 52.2517 50.91C52.5 50.3955 52.5 49.7952 52.5 48.595V41.5518C52.5 40.5423 52.5 40.0375 52.3338 39.605C52.1873 39.2228 51.9487 38.8825 51.6397 38.614C51.29 38.31 50.8155 38.1375 49.867 37.7925L41.85 34.8773C40.7462 34.476 40.1943 34.2752 39.6708 34.3092C39.209 34.3392 38.7648 34.497 38.3873 34.7645C37.9593 35.0677 37.6573 35.5713 37.053 36.5785L35 40C28.3753 36.9998 23.0048 31.6223 20 25L23.4216 22.947C24.4286 22.3428 24.9322 22.0407 25.2355 21.6126C25.503 21.2352 25.6608 20.7909 25.6908 20.3292C25.7248 19.8057 25.524 19.2538 25.1227 18.1501L22.2075 10.133C21.8625 9.1844 21.69 8.71007 21.3861 8.36025C21.1175 8.05123 20.7772 7.81288 20.3951 7.66613C19.9624 7.5 19.4577 7.5 18.4483 7.5H11.405C10.2047 7.5 9.60452 7.5 9.08995 7.74813C8.66375 7.95365 8.24535 8.34252 8.00925 8.75257C7.7242 9.24767 7.68437 9.79155 7.60472 10.8793C7.53532 11.8271 7.5 12.7844 7.5 13.75Z"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="white"
    />
  </svg>
);
export default PhoneSvg;
