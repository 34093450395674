import { SVGProps } from "react";

const ArrowDownSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="21"
    viewBox="0 0 16 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.06975 20.7071C7.46027 21.0976 8.09344 21.0976 8.48396 20.7071L14.8479 14.3431C15.2384 13.9526 15.2384 13.3195 14.8479 12.9289C14.4574 12.5384 13.8242 12.5384 13.4337 12.9289L7.77686 18.5858L2.12 12.9289C1.72948 12.5384 1.09631 12.5384 0.705788 12.9289C0.315263 13.3195 0.315263 13.9526 0.705788 14.3431L7.06975 20.7071ZM6.77686 0L6.77686 20H8.77686L8.77686 0L6.77686 0Z"
      fill="#2A1B3D"
    />
  </svg>
);
export default ArrowDownSvg;
