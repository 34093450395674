import { SVGProps } from "react";

const PhoneSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="80"
    height="60"
    viewBox="0 0 80 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 17L28.1664 29.111C32.4432 31.962 34.5817 33.3877 36.8927 33.9417C38.9353 34.431 41.0647 34.431 43.1073 33.9417C45.4183 33.3877 47.557 31.962 51.8337 29.111L70 17M20.6667 53.6667H59.3333C63.067 53.6667 64.934 53.6667 66.36 52.94C67.6143 52.301 68.6343 51.281 69.2733 50.0267C70 48.6007 70 46.7337 70 43V17.6667C70 13.933 70 12.0661 69.2733 10.6401C68.6343 9.38563 67.6143 8.36577 66.36 7.72663C64.934 7 63.067 7 59.3333 7H20.6667C16.933 7 15.0661 7 13.6401 7.72663C12.3856 8.36577 11.3658 9.38563 10.7266 10.6401C10 12.0661 10 13.933 10 17.6667V43C10 46.7337 10 48.6007 10.7266 50.0267C11.3658 51.281 12.3856 52.301 13.6401 52.94C15.0661 53.6667 16.933 53.6667 20.6667 53.6667Z"
      stroke="black"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="white"
    />
  </svg>
);
export default PhoneSvg;
