import { SVGProps } from "react";

const SpeakersSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100"
    height="94"
    viewBox="0 0 100 94"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.7334 90.9715L73.2364 91.3276V34.7091C73.2749 31.0139 70.6318 30.1788 67.1316 30.2579L31.8382 29.9019C29.2869 30.2437 26.344 29.8545 25.7334 33.8189V90.9715Z"
      stroke="#2A1B3D"
      strokeWidth="4"
    />
    <path d="M24.5889 46.6379H73.0458" stroke="#2A1B3D" strokeWidth="4" />
    <path
      d="M24.9704 84.2058C10.3333 84.9489 -8.66044 69.9558 9.3268 50.199C-5.77196 34.2279 7.02217 12.3123 29.549 17.9728C36.8331 -4.93397 65.7213 0.22516 69.9933 17.9728C105.444 10.5557 95.9509 42.7136 89.4523 50.3771C107.896 59.0192 90.9407 90.3132 73.8088 84.2058"
      stroke="#2A1B3D"
      strokeWidth="4"
    />
    <path
      d="M49.6281 71.4311C51.2348 71.4311 52.5374 70.2155 52.5374 68.7159C52.5374 67.2164 51.2348 66.0007 49.6281 66.0007C48.0213 66.0007 46.7188 67.2164 46.7188 68.7159C46.7188 70.2155 48.0213 71.4311 49.6281 71.4311Z"
      fill="#2A1B3D"
    />
    <path
      d="M49.7713 80.1195C56.4145 80.1195 61.7999 75.0536 61.7999 68.8045C61.7999 62.5554 56.4145 57.4895 49.7713 57.4895C43.1281 57.4895 37.7427 62.5554 37.7427 68.8045C37.7427 75.0536 43.1281 80.1195 49.7713 80.1195Z"
      stroke="#2A1B3D"
      strokeWidth="4"
    />
    <path d="M25.4707 68.8047H16.8623" stroke="#2A1B3D" strokeWidth="4" />
    <path d="M73.0444 68.9468H81.6528" stroke="#2A1B3D" strokeWidth="4" />
  </svg>
);
export default SpeakersSvg;
