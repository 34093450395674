import { FC } from "react";
import { styled } from "styled-components";
import Checkmark from "./icons/CheckmarkSvg";
import { device } from "../styles/Breakpoints";

const CheckboxContainer = styled.div`
  display: inline-block;
  position: relative;
  height: 22px;
`;

const Label = styled.label`
  position: relative;
  font-family: ${({ theme }) => theme.fonts.OpenSans};
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.darkPurple};
  cursor: pointer;

  &::after {
    content: "";
    height: 22px;
    width: 22px;
    margin-right: 8px;
    float: left;
    border: 2px solid ${({ theme }) => theme.colors.purple};
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 11px;
    transition: 0.15s all ease-out;
  }

  @media ${device.lg} {
    font-size: 18px;
  }
`;

const Input = styled.input`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
`;

interface Props {
  id: string;
  name: string;
  label: string;
  value: string;
  checked: boolean;
  onChange: (id: string, value: string | null) => void;
}

const Checkbox: FC<Props> = ({ id, name, label, value, checked, onChange }) => {
  const handleChange = () => {
    if (checked) {
      onChange?.(id, null);
    } else {
      onChange?.(id, value);
    }
  };

  return (
    <CheckboxContainer onClick={handleChange}>
      <Input
        id={id}
        name={name}
        type="checkbox"
        value={value}
        checked={checked}
        readOnly
      />
      <Label>{label}</Label>
      <Checkmark checked={checked} />
    </CheckboxContainer>
  );
};

export default Checkbox;
