import { styled } from "styled-components";
import { SectionTitle } from "../components/Text";
import { useTranslation } from "react-i18next";
import UserSvg from "../components/icons/UserSvg";
import SuitcaseSvg from "../components/icons/SuitcaseSvg";
import TruckSvg from "../components/icons/TruckSvg";
import { device } from "../styles/Breakpoints";

const Container = styled.section`
  position: relative;
  padding-bottom: 60px;

  @media ${device.sm} {
    background-image: url("./assets/offer_bg.svg");
    background-repeat: no-repeat;
    background-size: cover;
  }

  @media ${device.md} {
    padding-bottom: 100px;
  }
`;

const TitleContainer = styled.div`
  position: relative;
  padding: 60px 0 80px;

  @media ${device.md} {
    padding: 60px 0 100px;
  }
`;

const Title = styled(SectionTitle)`
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
`;

const OffersContainer = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-template:
    "individual"
    "business"
    "delivery";
  gap: 100px;
  padding: 0 15px;
  max-width: 360px;
  margin: 0 auto 50px;

  @media ${device.md} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template:
      "individual individual business business"
      ". delivery delivery .";
    justify-content: center;
    max-width: unset;
    margin: 0 0 50px;
  }

  @media ${device.lg} {
    grid-template: "individual business delivery";
    gap: 60px;
    padding: 0 60px;
    max-width: 1280px;
    margin: 0 auto 50px;
  }

  @media ${device.xl} {
    max-width: 1440px;
  }
`;

const OfferBox = styled.div`
  position: relative;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.lightGray};
  border-radius: 30px;
  box-shadow: 0px 11px 8px 11px rgba(0, 0, 0, 0.12);
  padding: 80px 30px 45px;
  min-height: 385px;
  transition: transform 0.4s ease;

  &:hover {
    transform: translateY(-10px);
  }

  @media ${device.sm} {
    width: 330px;
  }

  @media ${device.xl} {
    width: 350px;
  }
`;

const IndividualBox = styled(OfferBox)`
  grid-area: individual;
`;

const BusinessBox = styled(OfferBox)`
  grid-area: business;
`;

const DeliveryBox = styled(OfferBox)`
  grid-area: delivery;
`;

const OfferIconContainer = styled.div`
  position: absolute;
  width: 100px;
  height: 100px;
  top: -40px;
  left: 50%;
  transform: translateX(-50%);
  background-color: ${({ theme }) => theme.colors.darkPurple};
  border-radius: 50px;
`;

const CircleOutside = styled.div`
  position: absolute;
  width: 80px;
  height: 80px;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 40px;
`;

const CircleInside = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 60px;
  height: 60px;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  border: 3px solid ${({ theme }) => theme.colors.darkPurple};
  border-radius: 30px;
`;

const Icon = styled.div`
  position: relative;
  width: 35px;
  height: 35px;

  & > svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const OfferTitle = styled.h3`
  font-family: ${({ theme }) => theme.fonts.OpenSans};
  color: ${({ theme }) => theme.colors.darkPurple};
  font-size: 26px;
  font-weight: bold;
  text-align: center;
`;

const OfferContent = styled.div<{ $isBusinessOffer?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 30px 15px 10px;

  ${({ $isBusinessOffer }) =>
    $isBusinessOffer &&
    `
    gap: 10px;
  `}
`;

const OfferBottomLine = styled.div`
  position: absolute;
  width: 50px;
  height: 4px;
  background-color: ${({ theme }) => theme.colors.darkPurple};
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
`;

const OfferItemGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const OfferItemTitle = styled.h4`
  font-family: ${({ theme }) => theme.fonts.OpenSans};
  color: ${({ theme }) => theme.colors.darkPurple};
  font-size: 18px;
  font-weight: bold;
`;

const OfferItemValue = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  font-family: ${({ theme }) => theme.fonts.OpenSans};
  color: ${({ theme }) => theme.colors.darkPurple};
  font-size: 18px;
  font-weight: bold;
`;

const OfferItemValuePoint = styled.div`
  align-self: flex-start;
  position: relative;
  min-width: 10px;
  height: 10px;
  border-radius: 5px;
  margin-top: 9px;
  background-color: ${({ theme }) => theme.colors.darkPurple};
`;

// BUSINESS
const BusinessList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const AdditionalInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

const AdditionalInfoText = styled.p`
  font-family: ${({ theme }) => theme.fonts.OpenSans};
  color: ${({ theme }) => theme.colors.gray};
  font-size: 16px;
  font-weight: 500;
`;

const Offer = () => {
  const { t } = useTranslation();

  return (
    <Container id="offer">
      <TitleContainer>
        <Title>{t("offer:title")}</Title>
      </TitleContainer>
      <OffersContainer>
        <IndividualBox>
          <OfferIconContainer>
            <CircleOutside>
              <CircleInside>
                <Icon>
                  <UserSvg />
                </Icon>
              </CircleInside>
            </CircleOutside>
          </OfferIconContainer>
          <OfferTitle>{t("offer:individual:title")}</OfferTitle>
          <OfferContent>
            <OfferItemGroup>
              <OfferItemTitle>
                {t("offer:individual:pncz:title")}
              </OfferItemTitle>
              <OfferItemValue>
                <OfferItemValuePoint />
                {t("offer:individual:pncz:value")}
              </OfferItemValue>
            </OfferItemGroup>
            <OfferItemGroup>
              <OfferItemTitle>
                {t("offer:individual:ptnd:title")}
              </OfferItemTitle>
              <OfferItemValue>
                <OfferItemValuePoint />
                {t("offer:individual:ptnd:value")}
              </OfferItemValue>
            </OfferItemGroup>
          </OfferContent>
          <OfferBottomLine />
        </IndividualBox>
        <BusinessBox>
          <OfferIconContainer>
            <CircleOutside>
              <CircleInside>
                <Icon>
                  <SuitcaseSvg />
                </Icon>
              </CircleInside>
            </CircleOutside>
          </OfferIconContainer>
          <OfferTitle>{t("offer:business:title")}</OfferTitle>
          <OfferContent $isBusinessOffer={true}>
            <OfferItemTitle>{t("offer:business:rental:title")}</OfferItemTitle>
            <BusinessList>
              <OfferItemValue>
                <OfferItemValuePoint />
                {t("offer:business:rental:option1")}
              </OfferItemValue>
              <OfferItemValue>
                <OfferItemValuePoint />
                {t("offer:business:rental:option2")}
              </OfferItemValue>
              <OfferItemValue>
                <OfferItemValuePoint />
                {t("offer:business:rental:option3")}
              </OfferItemValue>
              <OfferItemValue>
                <OfferItemValuePoint />
                {t("offer:business:rental:option4")}
              </OfferItemValue>
            </BusinessList>
          </OfferContent>
          <OfferBottomLine />
        </BusinessBox>
        <DeliveryBox>
          <OfferIconContainer>
            <CircleOutside>
              <CircleInside>
                <Icon>
                  <TruckSvg />
                </Icon>
              </CircleInside>
            </CircleOutside>
          </OfferIconContainer>
          <OfferTitle>{t("offer:delivery:title")}</OfferTitle>
          <OfferContent>
            <OfferItemGroup>
              <OfferItemTitle>{t("offer:delivery:local:title")}</OfferItemTitle>
              <OfferItemValue>
                <OfferItemValuePoint />
                {t("offer:delivery:local:value")}
              </OfferItemValue>
            </OfferItemGroup>
            <OfferItemGroup>
              <OfferItemTitle>{t("offer:delivery:other:title")}</OfferItemTitle>
              <OfferItemValue>
                <OfferItemValuePoint />
                {t("offer:delivery:other:value")}
              </OfferItemValue>
            </OfferItemGroup>
          </OfferContent>
          <OfferBottomLine />
        </DeliveryBox>
      </OffersContainer>
      <AdditionalInfoContainer>
        <AdditionalInfoText>
          {t("offer:additionalInfo:prices")}
        </AdditionalInfoText>
        <AdditionalInfoText>
          {t("offer:additionalInfo:delivery")}
        </AdditionalInfoText>
      </AdditionalInfoContainer>
    </Container>
  );
};

export default Offer;
