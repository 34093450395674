// SECTIONS
import Hero from "../sections/Hero";
import AboutUs from "../sections/AboutUs";
import Offer from "../sections/Offer";
import Equipment from "../sections/Equipment";
import Contact from "../sections/Contact";
import Faqs from "../sections/Faqs";

const Home = () => {
  return (
    <>
      <Hero />
      <AboutUs />
      <Offer />
      <Equipment />
      <Contact />
      <Faqs />
    </>
  );
};

export default Home;
