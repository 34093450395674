import { SVGProps } from "react";

const SuccessSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="181"
    height="181"
    viewBox="0 0 181 181"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M160.744 91.2364C160.744 105.129 156.624 118.71 148.906 130.262C141.187 141.813 130.217 150.817 117.381 156.133C104.546 161.45 90.4222 162.841 76.7962 160.131C63.1702 157.42 50.6539 150.73 40.8301 140.906C31.0063 131.083 24.3162 118.566 21.6059 104.94C18.8955 91.3143 20.2865 77.1906 25.6031 64.3552C30.9197 51.5198 39.9231 40.5492 51.4746 32.8307C63.0262 25.1122 76.6072 20.9924 90.5001 20.9924C99.725 20.9913 108.86 22.8074 117.383 26.3371C125.906 29.8667 133.65 35.0408 140.173 41.5638C146.696 48.0868 151.87 55.8309 155.399 64.3538C158.929 72.8767 160.745 82.0115 160.744 91.2364ZM124.322 51.3277C123.523 50.865 122.641 50.5645 121.726 50.4434C120.81 50.3223 119.88 50.383 118.988 50.6221C118.096 50.8611 117.261 51.2737 116.529 51.8363C115.796 52.3989 115.183 53.1005 114.722 53.9007L80.758 112.726L65.3819 94.2708C64.7912 93.5624 64.0667 92.9773 63.2498 92.5488C62.433 92.1204 61.5397 91.8571 60.6211 91.7739C59.7024 91.6907 58.7764 91.7892 57.8958 92.0639C57.0153 92.3385 56.1974 92.784 55.489 93.3747C54.7806 93.9654 54.1955 94.6899 53.7671 95.5068C53.3386 96.3236 53.0753 97.2169 52.9921 98.1355C52.824 99.9908 53.3999 101.837 54.5929 103.268L76.4105 129.459C77.0695 130.25 77.8943 130.887 78.8265 131.324C79.7587 131.761 80.7755 131.987 81.805 131.988C82.0064 131.987 82.2077 131.979 82.4083 131.961C83.5357 131.865 84.6231 131.498 85.5778 130.891C86.5326 130.284 87.3264 129.455 87.8916 128.474L126.878 60.9189C127.339 60.1203 127.638 59.2386 127.759 58.3243C127.879 57.4099 127.818 56.4808 127.579 55.59C127.34 54.6992 126.928 53.8643 126.366 53.133C125.804 52.4016 125.104 51.7881 124.305 51.3277H124.322Z"
      fill="#D83F87"
    />
  </svg>
);
export default SuccessSvg;
