import { SVGProps } from "react";

const ArrowRightSvg = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 32 32"
      fill="none"
      {...props}
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M3.415 17.227c.005-.587.456-1.059 1.008-1.053l20.213.187-6.719-7.265a1.11 1.11 0 0 1 .012-1.503.959.959 0 0 1 1.415.013l8.41 9.095a1.11 1.11 0 0 1-.012 1.503l-8.559 8.937a.959.959 0 0 1-1.414-.013 1.11 1.11 0 0 1 .012-1.503l6.837-7.139L4.406 18.3c-.552-.005-.996-.485-.991-1.072Z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default ArrowRightSvg;
