import { styled } from "styled-components";
import { device } from "../styles/Breakpoints";

export const HeaderL = styled.h1`
  font-family: ${({ theme }) => theme.fonts.PaytoneOne};
  font-size: 70px;
`;

export const HeaderM = styled.h2`
  font-family: ${({ theme }) => theme.fonts.PaytoneOne};
  font-size: 60px;
`;

export const HeaderS = styled.h3`
  font-family: ${({ theme }) => theme.fonts.PaytoneOne};
  font-size: 48px;
`;

export const ParagraphL = styled.p`
  font-family: ${({ theme }) => theme.fonts.OpenSans};
  font-size: 32px;
`;

export const ParagraphM = styled.p`
  font-family: ${({ theme }) => theme.fonts.OpenSans};
  font-size: 24px;
`;

export const ParagraphS = styled.p`
  font-family: ${({ theme }) => theme.fonts.OpenSans};
  font-size: 18px;
`;

export const ParagraphXS = styled.p`
  font-family: ${({ theme }) => theme.fonts.OpenSans};
  font-size: 16px;
`;

export const MainTitle = styled.h1`
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.PaytoneOne};
  font-size: 40px;

  @media ${device.lg} {
    font-size: 60px;
  }

  @media ${device.xxl} {
    font-size: 70px;
  }
`;

export const SectionTitle = styled.h2`
  color: ${({ theme }) => theme.colors.primaryText};
  font-family: ${({ theme }) => theme.fonts.PaytoneOne};
  font-size: 32px;

  @media ${device.md} {
    font-size: 40px;
  }

  @media ${device.xl} {
    font-size: 48px;
  }
`;
